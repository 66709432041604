import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
    public isLoading$ = this.isLoadingSubject.asObservable();
    public loaderType: string | undefined;

    showLoader(loaderType: string) {
        this.loaderType = loaderType;
        this.isLoadingSubject.next(true);
    }

    hideLoader() {
        this.isLoadingSubject.next(false);
    }
}
